import type { AlternativeUser } from "~/api/osteo-physio/types/models/user/alternative"
import type { Modify } from "~/types/helpers/modify"

/**
 * Tidy up methods should take the exact data returned by the Osteo & Physio API and return a better typed version of it.
 * Property names should be kept identical. No additional properties should be added, and none removed either.
 */

/**
 * Represents the badly typed user returned by the API.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export type BadlyTypedAlternativeUser = Modify<
	AlternativeUser,
	{
		// API returns this number as a string, also why isn't this called 'user_id' to be consistent?
		patient_id: string
	}
>

/**
 * Cleans up the types on a badly typed alternative user returned by the API.
 * @param {BadlyTypedAlternativeUser} user The badly typed alternative user.
 * @returns {AlternativeUser} The cleaned up alternative user.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export const tidyUpAlternativeUser = (user: BadlyTypedAlternativeUser): AlternativeUser => ({
	...user,
	patient_id: parseInt(user.patient_id)
})
