import type { Practitioner } from "~/api/osteo-physio/types/models/data/core/practitioner"
import { orNull } from "~/helpers/null"
import type { Modify } from "~/types/helpers/modify"

/**
 * Tidy up methods should take the exact data returned by the Osteo & Physio API and return a better typed version of it.
 * Property names should be kept identical. No additional properties should be added, and none removed either.
 */

/**
 * Represents the badly typed practitioner returned by the API.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type BadlyTypedPractitioner = Modify<
	Practitioner,
	{
		name: string // Why can this be an empty string?! Practitioner without a picture & name??
		image: string // Can be absolute URL, relative path, empty string, a directory, or even a file (hopefully)
	}
>

/**
 * Represents a cleaned up practitioner returned by the API.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type NullablePractitioner = Modify<Practitioner, { name: string | null }>

/**
 * Cleans up the types on a badly typed practitioner returned by the API.
 * @param {BadlyTypedPractitioner} practitioner The badly typed practitioner.
 * @returns {NullablePractitioner} The cleaned up practitioner, who's name may be null.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const tidyUpPractitioner = (practitioner: BadlyTypedPractitioner): NullablePractitioner => ({
	...practitioner,

	name: orNull(practitioner.name),
	image: orNull(!practitioner.image.startsWith(".") ? practitioner.image : null)
})

/**
 * Cleans up the types on multiple badly typed practitioners returned by the API.
 * This automatically removes any practitioners without a name.
 * @param {BadlyTypedPractitioner} practitioner The badly typed practitioner.
 * @returns {Practitioner} The cleaned up practitioner.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const tidyUpPractitioners = (practitioners: BadlyTypedPractitioner[]): Practitioner[] =>
	practitioners.map(tidyUpPractitioner).filter(({ name }) => name !== null) as Practitioner[]
