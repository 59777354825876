import type { ReactEventHandler } from "react"
import { useCallback, useState } from "react"
import { ReactSVG } from "react-svg"
import { longerOpacityTransitionStyles } from "~/config/transitions"

import type { ComponentProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML raster image/scalable vector.
 * @example <Image sourceUrl="https://example.com/demo.png" screenReaderDescription="Raster" />
 * @example <Image sourceUrl="https://example.com/demo.svg" screenReaderDescription="Vector" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const Image = ({
	sourceUrl,
	fallbackUrl,

	screenReaderDescription,

	width = "100%",
	height = "auto",

	...props
}: ComponentProps<
	HTMLImageElement,
	{
		sourceUrl: string
		fallbackUrl?: string

		screenReaderDescription: string

		width?: string | number
		height?: string | number
	}
>): JSX.Element => {
	const onError = useCallback<ReactEventHandler<HTMLImageElement>>(
		({ nativeEvent }) => {
			if (nativeEvent.target === null) return
			const target = nativeEvent.target as HTMLImageElement

			console.warn(`Failed to load image '${target.src}'!`)

			if (fallbackUrl !== undefined && fallbackUrl !== target.src) target.src = fallbackUrl
		},
		[fallbackUrl]
	)

	const [isLoaded, setIsLoaded] = useState<boolean>(false)
	const onLoad = useCallback<ReactEventHandler<HTMLImageElement>>(() => {
		setIsLoaded(true)
	}, [])

	if (sourceUrl.endsWith(".svg"))
		return (
			<ReactSVG
				src={sourceUrl}
				title={screenReaderDescription}
				aria-label={screenReaderDescription}
				className={`fill-primary object-contain ${props.className ?? ""}`.trimEnd()}
				style={{
					width: width,
					height: height
				}}
			/>
		)

	return (
		<img
			{...props}
			src={sourceUrl}
			alt={screenReaderDescription}
			title={screenReaderDescription}
			aria-label={screenReaderDescription}
			referrerPolicy="no-referrer"
			loading="lazy"
			onError={onError}
			onLoad={onLoad}
			className={`${longerOpacityTransitionStyles} object-contain ${isLoaded ? "opacity-100" : "cursor-wait opacity-0"} ${props.className ?? ""}`.trimEnd()}
			style={{
				width: width,
				height: height
			}}
		/>
	)
}

export default Image
