import type { AppointmentFilters } from "~/api/osteo-physio/types/endpoints/appointments/filters"
import type { Core } from "~/api/osteo-physio/types/endpoints/data/core"
import type { Location } from "~/api/osteo-physio/types/models/data/core/location"
import type { Practitioner } from "~/api/osteo-physio/types/models/data/core/practitioner"
import { tidyUpLocations } from "~/helpers/tidy-ups/location"
import { tidyUpPractitioners } from "~/helpers/tidy-ups/practitioner"

/**
 * Key-value pairs of identifiers (numbers) to values (strings) for the filterable types.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export type FilterableTypes = Map<number, string>

/**
 * Filters out unnecessary filter types based on the provided core data & available filters.
 * This automatically tidies up the locations & practitioners.
 * @param {Core | undefined} core The core data from the API. This is optional so it can be used in loading states.
 * @param {AppointmentFilters | undefined} availableFilters The possible filters from the API. This is optional so it can be used in loading states. This is not required when discarding therapy types.
 * @returns {object} The relevant filter types.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const discardIrrelevantFilterableTypes = (
	core?: Core,
	availableFilters?: AppointmentFilters,

	discardWithoutFilters = false
): {
	therapyTypes?: FilterableTypes
	appointmentTypes?: FilterableTypes

	genders?: FilterableTypes // Gender is just a string instead of a model, so we can get away with reusing this type
	locations?: Map<number, Location>
	practitioners?: Map<number, Practitioner>
} => {
	// Don't bother if we don't have the required data
	if (!core)
		return {
			therapyTypes: undefined,
			appointmentTypes: undefined,

			genders: undefined,
			locations: undefined,
			practitioners: undefined
		}

	// We never want to filter out therapy types as that's the root filter, if we do then the user will never be able to select another
	const therapyTypes = new Map(
		[...core.appointment_options.therapies]
			.sort(({ id: a }, { id: b }) => a - b) // Sort numerically
			//.sort(({ name: a }, { name: b }) => a.localeCompare(b)) // Sort alphabetically
			.map(({ id, name }) => [id, name])
	)

	// Give up now if we don't have the available filters from the API, we can't filter out anything else
	if (!availableFilters)
		return {
			therapyTypes,

			/* eslint-disable indent */
			appointmentTypes: discardWithoutFilters
				? new Map(
						[...core.appointment_options.appointment_types]
							.sort(({ id: a }, { id: b }) => a - b) // Sort numerically
							.map(({ id, name }) => [id, name])
					)
				: undefined,

			genders: discardWithoutFilters
				? new Map(
						[...core.appointment_options.genders]
							.sort(({ id: a }, { id: b }) => a - b) // Sort numerically
							.map(({ id, name }) => [id, name])
					)
				: undefined,
			locations: discardWithoutFilters
				? new Map(
						[...tidyUpLocations(core.appointment_options.locations)]
							.sort(({ id: a }, { id: b }) => a - b) // Sort numerically
							.map(location => [location.id, location])
					)
				: undefined,
			practitioners: discardWithoutFilters
				? new Map(
						[...tidyUpPractitioners(core.appointment_options.practitioners)]
							.sort(({ id: a }, { id: b }) => a - b) // Sort numerically
							.map(practitioner => [practitioner.id, practitioner])
					)
				: undefined
		}

	return {
		therapyTypes,
		appointmentTypes: new Map(
			[...core.appointment_options.appointment_types]
				.filter(({ id }) => availableFilters.appt_type_id.includes(id))
				.sort(({ id: a }, { id: b }) => a - b) // Sort numerically
				//.sort(({ name: a }, { name: b }) => a.localeCompare(b)) // Sort alphabetically
				.map(({ id, name }) => [id, name])
		),

		genders: new Map(
			[...core.appointment_options.genders]
				.filter(({ id }) => availableFilters.gender_id.includes(id))
				.sort(({ id: a }, { id: b }) => a - b) // Sort numerically
				//.sort(({ name: a }, { name: b }) => a.localeCompare(b)) // Sort alphabetically
				.map(({ id, name }) => [id, name])
		),
		locations: new Map(
			[...tidyUpLocations(core.appointment_options.locations)]
				.filter(({ id }) => availableFilters.location_id.includes(id))
				.sort(({ id: a }, { id: b }) => a - b) // Sort numerically
				//.sort(({ city: a }, { city: b }) => a.localeCompare(b)) // Sort alphabetically
				.map(location => [location.id, location])
		),
		practitioners: new Map(
			[...tidyUpPractitioners(core.appointment_options.practitioners)]
				.filter(({ id }) => availableFilters.practitioner_id.includes(id))
				.sort(({ id: a }, { id: b }) => a - b) // Sort numerically
				//.sort(({ name: a }, { name: b }) => a.localeCompare(b)) // Sort alphabetically
				.map(practitioner => [practitioner.id, practitioner])
		)
	}
}
