import { PencilIcon } from "@heroicons/react/24/solid"

import type { InputProps } from "~/components/controls/inputs/input"
import Input from "~/components/controls/inputs/input"
import { longerColorTransitionStyles } from "~/config/transitions"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import type { ComponentProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML text input.
 * @example <TextInput id="nameInput" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const TextInput = ({
	tooltip = "Please enter text.",
	regularExpression = "^[\\w\\d ]{1,100}$",

	startIcon,

	...props
}: ComponentProps<HTMLInputElement, Omit<InputProps, "type">>): JSX.Element => (
	<Input
		{...props}
		type="text"
		inputMode="text"
		tooltip={tooltip}
		regularExpression={regularExpression}
		startIcon={isLoading =>
			/* eslint-disable indent */
			typeof startIcon === "function"
				? startIcon(isLoading)
				: startIcon ?? (
						<PencilIcon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)
		}
	/>
)

export default TextInput
