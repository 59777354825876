import type { Location } from "~/api/osteo-physio/types/models/data/core/location"
import { orNull } from "~/helpers/null"
import type { Modify } from "~/types/helpers/modify"

/**
 * Tidy up methods should take the exact data returned by the Osteo & Physio API and return a better typed version of it.
 * Property names should be kept identical. No additional properties should be added, and none removed either.
 */

/**
 * Represents the badly typed location returned by the API.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type BadlyTypedLocation = Modify<
	Location,
	{
		name: string // Can be an empty string
		addr_1: string // Can be an empty string
		addr_2: string // Can be an empty string
		city: string // Can be an empty string
		state: string // Can be an empty string
		post_code: string // Can be an empty string

		latlong: string // Comma-separated latitude & longitude floats, can be an empty string

		directions: string // Can be an empty string
	}
>

/**
 * Represents a cleaned up location returned by the API.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type NullableLocation = Modify<
	Location,
	{
		name: string | null
		addr_1: string | null
		city: string | null
		state: string | null
		post_code: string | null

		latlong: number[] | null
	}
>

/**
 * Cleans up the types on a badly typed location returned by the API.
 * @param {BadlyTypedLocation} location The badly typed location.
 * @returns {Location} The cleaned up location.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const tidyUpLocation = (location: BadlyTypedLocation): NullableLocation => ({
	...location,

	name: orNull(location.name),
	addr_1: orNull(location.addr_1),
	addr_2: orNull(location.addr_2),
	city: orNull(location.city),
	state: orNull(location.state),
	post_code: orNull(location.post_code),

	address: [
		orNull(location.addr_1),
		orNull(location.addr_2),
		orNull(location.city),
		orNull(location.state),
		orNull(location.post_code)
	]
		.filter(value => value !== null)
		.join(", "),

	latlong: location.latlong.split(",", 2).map(value => parseFloat(value.trim())),

	directions: orNull(location.directions)
})

/**
 * Cleans up the types on multiple badly typed location returned by the API.
 * This automatically removes any locations without a first address line, city or post code.
 * @param {BadlyTypedLocation} location The badly typed location.
 * @returns {Location} The cleaned up location.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const tidyUpLocations = (locations: BadlyTypedLocation[]): Location[] =>
	locations
		.map(tidyUpLocation)
		.filter(
			location =>
				location.name !== null &&
				location.addr_1 !== null &&
				location.city !== null &&
				location.post_code !== null
		) as Location[]
