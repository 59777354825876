import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"
import type { ChangeEventHandler, FormEvent, HTMLInputTypeAttribute, KeyboardEvent } from "react"
import { useCallback, useEffect, useRef } from "react"

import { Color } from "~/colors"
import Paragraph from "~/components/standard/text/paragraph"
import FadeIn from "~/components/wrappers/fadeIn"
import { longerColorTransitionStyles, standardOpacityTransitionStyles } from "~/config/transitions"
import { inputLinkIconSize, inputWarningIconSize } from "~/constants/components/input"
import { useFormContext } from "~/contexts/form"
import { isApple } from "~/helpers/device"
import { useFormSelector } from "~/hooks/useForm"
import { useInputDispatch, useInputSelector } from "~/hooks/useInput"
import type { DoRenderCallback } from "~/types/components/callbacks"
import type { OnClickCallback } from "~/types/components/controls/button"
import type { ComponentProps } from "~/types/components/props"

export interface InputProps {
	id: string

	label?: string
	tooltip?: string

	value?: string
	initialValue?: string
	placeholder?: string

	regularExpression?: string
	minimumLength?: number
	maximumLength?: number

	startIcon?: JSX.Element | DoRenderCallback
	//endIcon?: JSX.Element | DoRenderCallback

	linkText?: string
	linkIcon?: JSX.Element | DoRenderCallback
	isLinkDisabled?: boolean
	onLinkClick?: OnClickCallback

	isDisabled?: boolean
	isReadOnly?: boolean
	isRequired?: boolean
	isFocused?: boolean
	isLoading?: boolean

	missingValueWarningMessage?: string

	transition?: boolean

	sideBySide?: boolean
}

/**
 * A pre-styled generic HTML input.
 * This should not be used directly, instead use a more specific input such as <EmailAddressInput /> or <PhoneNumberInput />.
 * @example <Input id="exampleInput" type="text" label="Hello World" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const Input = ({
	id,
	type,

	label,
	tooltip,

	initialValue,
	placeholder,

	regularExpression,
	minimumLength = 1,
	maximumLength = 100, // Somewhat reasonable default

	startIcon,
	//endIcon,

	isDisabled = false,
	isReadOnly = false,
	isRequired = true,
	isFocused = false,
	isLoading = false,

	// Text to the right of the label, above the input
	linkText,
	linkIcon,
	isLinkDisabled = isDisabled,
	onLinkClick,

	missingValueWarningMessage,

	...props
}: ComponentProps<
	HTMLInputElement,
	{
		type: HTMLInputTypeAttribute
	} & InputProps
>): JSX.Element => {
	// Form
	const { id: formId } = useFormContext()
	const { isLoading: isFormLoading } = useFormSelector()

	// Input
	const { showWarning, clearWarning } = useInputDispatch(id)
	const { warningMessage } = useInputSelector(id)

	const inputReference = useRef<HTMLInputElement | null>(null)

	// Runs when a key is pressed...
	const onKeyDown = useCallback(
		(event: KeyboardEvent<HTMLInputElement>): void => {
			// Always allow modifier keys
			if (event.ctrlKey || event.altKey || event.metaKey) return

			// Always allow backspace, delete, space, escape & enter
			if (["Backspace", "Delete", " ", "Escape", "Enter"].includes(event.key)) return

			// Always allow arrow keys
			if (["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(event.key)) return

			// If this is a phone number input, prevent everything except plus & numbers from being entered
			if (type === "tel" && !/^[+\d]+$/.test(event.key)) event.preventDefault()

			// If this is a number input, prevent everything except numbers from being entered
			if (type === "number" && !/^\d+$/.test(event.key)) event.preventDefault()
		},
		[type]
	)

	// Runs when the parent form is submitted and this input is invalid...
	const onInvalid = useCallback(
		(event: FormEvent<HTMLInputElement>): void => {
			event.preventDefault() // Prevent showing the browser's default validation message

			// If there's already a warning message, don't show another one
			if (warningMessage !== null) return

			const input = event.currentTarget

			if (input.validity.valueMissing) {
				if (type === "tel") {
					console.warn(
						`No value entered for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
					)
					showWarning("Enter a phone number!")
				} else if (type === "number") {
					console.warn(
						`No number entered for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
					)
					showWarning("Enter a number!")
				} else {
					console.warn(
						`No value entered for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
					)
					showWarning(missingValueWarningMessage ?? "Enter a value!")
				}
			} else if (input.validity.rangeOverflow) {
				console.warn(
					`Numeric value too high for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
				)
				showWarning(`Enter a number less than ${maximumLength.toString()}!`)
			} else if (input.validity.rangeUnderflow) {
				console.warn(
					`Numeric value too low for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
				)
				showWarning(`Enter a number greater than ${minimumLength.toString()}!`)
			} else if (input.validity.tooLong) {
				if (type === "tel") {
					console.warn(
						`Phone number too long for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
					)
					showWarning(`Enter a phone number less than ${maximumLength.toString()} number(s)!`)
				} else {
					console.warn(
						`Text value number too long for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
					)
					showWarning(`Enter a value shorter than ${maximumLength.toString()} character(s)!`)
				}
			} else if (input.validity.tooShort) {
				if (type === "tel") {
					console.warn(
						`Phone number too short for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
					)
					showWarning(`Enter a phone number greater than ${minimumLength.toString()} numbers!`)
				} else {
					console.warn(
						`Text value too short for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
					)
					showWarning(`Enter a value longer than ${minimumLength.toString()} character(s)!`)
				}
			} else {
				if (type === "tel") {
					console.warn(
						`Invalid phone number for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
					)
					showWarning("Enter a valid phone number!")
				} else if (type === "number") {
					console.warn(`Invalid number for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`)
					showWarning("Enter a valid number!")
				} else if (type === "date") {
					console.warn(`Invalid date for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`)
					showWarning("Enter a valid date in the DD / MM / YYYY format!")
				} else if (type === "email") {
					console.warn(
						`Invalid email address for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
					)
					showWarning("Enter a valid email address!")
				} else {
					console.warn(
						`Invalid text value for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`
					)
					showWarning("Enter a valid value!")
				}
			}
		},
		[showWarning, formId, warningMessage, id, type, minimumLength, maximumLength, missingValueWarningMessage]
	)

	// Clear warnings when the value changes...
	const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(() => {
		if (warningMessage !== null) clearWarning()
	}, [clearWarning, warningMessage])

	// Focus after loading...
	useEffect(() => {
		if (isFocused && inputReference.current && inputReference.current.value === "") inputReference.current.focus()
	})

	return (
		<div
			className={`flex ${type === "checkbox" ? "flex-row-reverse items-center justify-end gap-x-2" : "flex-col gap-y-2"}`}>
			<div className="flex flex-row items-end justify-between">
				{label !== undefined && (
					<label htmlFor={id} className="text-sm font-bold text-text">
						{label}
						{isRequired && <span className="ms-1 text-red-600">*</span>}
					</label>
				)}
				{linkText !== undefined && (
					<Paragraph
						className={`flex flex-row gap-x-1 text-right text-xs ${(isDisabled && isLinkDisabled) || isLoading || isFormLoading ? "text-gray-400 hover:cursor-not-allowed" : "text-primary hover:cursor-pointer hover:underline"}`}
						onClick={onLinkClick}>
						{linkText}
						{/* eslint-disable indent */}
						{typeof linkIcon === "function"
							? linkIcon(isLoading || isFormLoading, isDisabled && isLinkDisabled)
							: linkIcon ?? (
									<ArrowTopRightOnSquareIcon width={inputLinkIconSize} height={inputLinkIconSize} />
								)}
					</Paragraph>
				)}
			</div>
			<div className="flex flex-row">
				<div
					key={initialValue}
					className={`${longerColorTransitionStyles} flex w-full ${isApple() ? "text-base" : "text-sm"} ${type === "checkbox" ? "rounded-full bg-red-200" : `items-center justify-center rounded-lg border p-2 text-center align-middle shadow-sm ${warningMessage !== null ? "border-warning" : "border-controlBorder"} ${isDisabled || isLoading || isFormLoading ? "bg-gray-300 hover:cursor-not-allowed" : "bg-white"}`}`.trimEnd()}>
					{type !== "checkbox" &&
						(typeof startIcon === "function"
							? startIcon(isDisabled || isLoading || isFormLoading)
							: startIcon)}
					<input
						ref={inputReference}
						{...props}
						id={id}
						name={id}
						type={type === "date" ? "text" : type} // Cannot use actual date inputs as Firefox doesn't support hiding the little calendar icon!
						defaultValue={initialValue}
						aria-label={label}
						title={tooltip ?? label ?? "Please enter a value"}
						alt={tooltip ?? label ?? "Please enter a value"}
						placeholder={placeholder}
						aria-placeholder={placeholder}
						disabled={isDisabled || isLoading || isFormLoading}
						// aria-disabled={isDisabled || (isLoading || isFormLoading)}
						readOnly={isReadOnly}
						// aria-readonly={isReadOnly}
						required={isRequired}
						// aria-required={isRequired}
						autoFocus={isFocused}
						pattern={
							regularExpression ??
							"^.{" + minimumLength.toString() + "," + maximumLength.toString() + "}$" // Ugly but can't use string interpolation for regex patterns
						}
						minLength={minimumLength}
						maxLength={maximumLength}
						min={minimumLength}
						max={maximumLength}
						className={`flex flex-grow bg-transparent px-2 text-center ${type === "checkbox" ? "h-[16px] w-[48px] rounded-full border-none bg-red-200" : ""} ${isDisabled || isLoading || isFormLoading ? "hover:cursor-not-allowed" : ""} ${props.className ?? ""}`.trimEnd()}
						onKeyDown={onKeyDown}
						onInvalid={onInvalid}
						onChange={onChange}
					/>
					{type !== "checkbox" && (
						<FadeIn>
							<ExclamationCircleIcon
								className={`${standardOpacityTransitionStyles} ${warningMessage !== null ? "opacity-100" : "opacity-0"}`.trimEnd()}
								fill={Color.Warning}
								width={inputWarningIconSize}
								height={inputWarningIconSize}
							/>
						</FadeIn>
					)}
					{/* Hack for Firefox to cover the calender icon on a native date input */}
					{/* {window.navigator.userAgent.includes("Firefox") && type === "date" && (
						<div className="absolute right-24 h-6 w-6 bg-white"></div>
					)} */}
				</div>
			</div>
			{warningMessage !== null && (
				<FadeIn>
					<Paragraph className="text-center text-xs font-bold text-warning">{warningMessage}</Paragraph>
				</FadeIn>
			)}
		</div>
	)
}

export default Input
