import {
	ArrowDownTrayIcon,
	ArrowUpOnSquareIcon,
	ArrowUpRightIcon,
	ChatBubbleLeftEllipsisIcon,
	Cog6ToothIcon,
	InformationCircleIcon,
	UserCircleIcon,
	UserGroupIcon
} from "@heroicons/react/24/outline"
import { UserGroupIcon as UserGroupIconSolid } from "@heroicons/react/24/solid"
import type { Dispatch, SetStateAction } from "react"
import { useCallback, useEffect, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"

import { useFetchCoreQuery, useLazySwitchUserQuery } from "~/api/osteo-physio/client"
import { ErrorMessages, isAPIError, isHTTPError, isStandardError } from "~/api/osteo-physio/types/error"
import Button from "~/components/controls/button"
import Form from "~/components/controls/forms/form"
import Image from "~/components/controls/image"
import DropDownInput from "~/components/controls/inputs/dropDown"
import Paragraph from "~/components/standard/text/paragraph"
import { longerColorTransitionStyles, standardTransitionStyles } from "~/config/transitions"
import { dropDownIconStyles } from "~/constants/components/dropDown"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import { isApple } from "~/helpers/device"
import { prettyPhoneNumber } from "~/helpers/phoneNumber"
import { tidyUpAlternativeUser } from "~/helpers/tidy-ups/user/alternative"
import { tidyUpUser } from "~/helpers/tidy-ups/user/user"
import { useAuthSessionDispatch, useAuthSessionSelector } from "~/hooks/useAuthSession"
import { useFormDispatch } from "~/hooks/useForm"
import { useModalDispatch } from "~/hooks/useModal"
import { Routes } from "~/router"
import { isPartialUser } from "~/state/slices/authSession"
import { ButtonThemes, type OnClickCallback } from "~/types/components/controls/button"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import type { ComponentProps } from "~/types/components/props"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const contactEmailAddress = import.meta.env.VITE_CONTACT_EMAIL_ADDRESS
if (!contactEmailAddress) throw new Error("The Osteo & Physio contact email address is missing!")
const contactPhoneNumber = import.meta.env.VITE_CONTACT_PHONE_NUMBER
if (!contactPhoneNumber) throw new Error("The Osteo & Physio contact phone number is missing!")

enum HTMLElementIdentifiers {
	AccountMenu = "accountMenu",

	SwitchUserPopup = "switchUserPopup",
	UserDropDown = "userSelection"
}

// The width of the button icons within the menu
const menuIconSize = 20

/**
 * The account menu used by the header.
 * @param {boolean} isVisible Whether the menu is visible.
 * @example <AccountMenu isVisible={true} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const AccountMenu = ({
	isVisible = true,
	setMenuVisibility,

	...props
}: ComponentProps<
	HTMLDivElement,
	{
		isVisible?: boolean
		setMenuVisibility?: Dispatch<SetStateAction<boolean>>
	}
>): JSX.Element => {
	// Router
	const navigate = useNavigate()

	// Modal
	const { showNoticeModal, showInputModal, hideModal } = useModalDispatch()

	// Form
	const { updateLoading, showWarning } = useFormDispatch(HTMLElementIdentifiers.SwitchUserPopup)

	// Auth
	const { clearAuthSession, updateSessionUser } = useAuthSessionDispatch()
	const { user, isSignedIn } = useAuthSessionSelector()

	// API queries
	const { data: core, refetch } = useFetchCoreQuery({})
	const [switchUser, switchUserResponse] = useLazySwitchUserQuery()

	// Tidy up alternative users
	const alternativeUsers = useMemo(() => core?.alternative_users.map(tidyUpAlternativeUser) ?? [], [core])

	// Show a popup modal when the messages action is clicked...
	const onMessagesClick = useCallback<OnClickCallback>(() => {
		setMenuVisibility?.(false)

		showNoticeModal("Coming Soon", () => (
			<>
				<Paragraph>Messaging functionality is in the works!</Paragraph>
				<Paragraph>
					Contact us at{" "}
					<a href={`mailto:${contactEmailAddress}`} target="_blank" rel="noopener noreferrer">
						{contactEmailAddress}
					</a>{" "}
					or{" "}
					<a href={`tel:${contactPhoneNumber}`} target="_blank" rel="noopener noreferrer">
						{prettyPhoneNumber(contactPhoneNumber)}
					</a>{" "}
					for more information.
				</Paragraph>
			</>
		))
	}, [setMenuVisibility, showNoticeModal])

	// Redirect when the settings action is clicked...
	const onSettingsClick = useCallback<OnClickCallback>(() => {
		setMenuVisibility?.(false)

		navigate(Routes.Settings)
	}, [setMenuVisibility, navigate])

	// Redirect when the account action is clicked...
	const onAccountClick = useCallback<OnClickCallback>(() => {
		setMenuVisibility?.(false)

		navigate(Routes.Account)
	}, [setMenuVisibility, navigate])

	// Install PWA when the install app action is clicked...
	const onInstallAppClick = useCallback<OnClickCallback>(() => {
		// If we're on an iOS device, show a notice modal with instructions on how to install the app as PWAs are no longer supported :/
		if (isApple()) {
			setMenuVisibility?.(false)

			showNoticeModal("Install Instructions", () => (
				<div className="flex flex-col gap-y-2">
					<Paragraph>
						Please add this to your mobile phone by pressing the{" "}
						<ArrowUpOnSquareIcon width={20} height={20} className="mb-1 inline" /> icon displayed on the
						browser bar either above or below, then scroll down and press &quot;add to home screen&quot;
					</Paragraph>
					<Image
						sourceUrl="/images/screenshots/safari-share.jpeg"
						screenReaderDescription="The share button on Safari."
						className="w-full rounded-lg border border-controlBorder shadow"
					/>
				</div>
			))

			return
		}

		if (!globalThis.progressiveWebAppInstallEvent) return // Do not continue if we haven't captured the install event
		if (localStorage.getItem("dismissInstallPrompt") === "true") return // Do not continue if the user has previously dismissed the prompt or installed the app

		setMenuVisibility?.(false)

		/* eslint-disable promise/prefer-await-to-then */
		globalThis.progressiveWebAppInstallEvent
			.prompt()
			.then((data: object) => {
				const { outcome } = data as {
					outcome: "dismissed" | "accepted"
					platform: string
				}

				if (outcome === "dismissed") console.warn("PWA installation aborted!")

				// Lets never show the install prompt again
				localStorage.setItem("dismissInstallPrompt", "true")

				return data
			})
			.catch((error: unknown) => {
				console.warn(`Failed to install PWA! (${error?.toString() ?? "Unknown error"})`)
			})
	}, [setMenuVisibility, showNoticeModal])

	// Clear the authentication/session data when the sign out action is clicked...
	const onSignOutClick = useCallback<OnClickCallback>(() => {
		setMenuVisibility?.(false)

		clearAuthSession()
	}, [setMenuVisibility, clearAuthSession])

	// Show a popup modal when the about action is clicked...
	const onAboutClick = useCallback<OnClickCallback>(() => {
		setMenuVisibility?.(false)

		showNoticeModal("About", () => (
			<>
				{/* Internally we semver'd the PWA rebuild as 2.0.0, but client wants a different one displayed :? */}
				{/* Bump this by 0.01 for each production deployment ;) */}
				<Paragraph>Osteo and Physio app version 1.06.</Paragraph>
				<Paragraph>
					Osteo and Physio provides osteopathy, physiotherapy, sports therapy and massage from comfortable and
					conveniently located clinics across the UK.
				</Paragraph>
				<Paragraph>
					Contact us at{" "}
					<a href={`mailto:${contactEmailAddress}`} target="_blank" rel="noopener noreferrer">
						{contactEmailAddress}
					</a>{" "}
					or{" "}
					<a href={`tel:${contactPhoneNumber}`} target="_blank" rel="noopener noreferrer">
						{prettyPhoneNumber(contactPhoneNumber)}
					</a>
					.
				</Paragraph>
				<Paragraph>
					View our{" "}
					<Link to={Routes.TermsConditions} onClick={hideModal}>
						Terms & Conditions
					</Link>
					.
				</Paragraph>
				{(import.meta.env.DEV || import.meta.env.VITE_BASE_URL.includes("dev")) && (
					<Paragraph className="text-xs text-subtleOnSecondary">
						{VITE_BITBUCKET_TAG !== undefined ? (
							<a
								href={`https://bitbucket.org/yellostudio/osteo-physio/commits/tag/${VITE_BITBUCKET_TAG}`}
								target="_blank"
								rel="noopener noreferrer">
								{VITE_BITBUCKET_TAG}
							</a>
						) : (
							"0.0.0"
						)}{" "}
						(
						{VITE_BITBUCKET_COMMIT !== undefined ? (
							<a
								href={`https://bitbucket.org/yellostudio/osteo-physio/commits/${VITE_BITBUCKET_COMMIT}`}
								target="_blank"
								rel="noopener noreferrer">
								{VITE_BITBUCKET_COMMIT.substring(0, 7)}
							</a>
						) : (
							"N/A"
						)}
						) @ {new Date(VITE_BUILT_AT).toISOString()} ({import.meta.env.MODE}).
					</Paragraph>
				)}
			</>
		))
	}, [setMenuVisibility, showNoticeModal, hideModal])

	// Switch user when the popup modal form is submitted...
	const onSwitchUserSubmit = useCallback<OnFormSubmitCallback>(
		values => {
			if (!user || isPartialUser(user)) return // Do not continue if the user is not fully signed in

			const userIdentifier = values.get(HTMLElementIdentifiers.UserDropDown) as number | null

			// Shouldn't happen, but check just in case to please TypeScript
			if (userIdentifier === null) {
				console.warn("No user was selected, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.UserDropDown, "Select a user!")
				return
			}

			/* eslint-disable promise/prefer-await-to-then */
			updateLoading(true)
			switchUser({
				id: userIdentifier
			}).catch((error: unknown) => {
				console.warn(`Failed to switch user! (${error?.toString() ?? "Unknown error"})`)
				showWarning("switchUser", "Sorry, an unknown problem occurred. Please try again later.")
			})
		},
		[switchUser, showWarning, updateLoading, user]
	)

	// Shows a popup modal with a list of alternative users...
	const onSwitchUserClick = useCallback<OnClickCallback>(() => {
		if (alternativeUsers.length <= 0) return // Do not continue if there are no alternative users
		if (!user || isPartialUser(user)) return // Do not continue if the user is not fully signed in

		setMenuVisibility?.(false)

		showInputModal(HTMLElementIdentifiers.SwitchUserPopup, "Switch User", () => (
			<>
				<Paragraph>
					Select an alternative user below to switch to. You will be able to view and book appointments on
					their behalf.
				</Paragraph>
				<Form id={HTMLElementIdentifiers.SwitchUserPopup} onSubmit={onSwitchUserSubmit}>
					<DropDownInput
						id={HTMLElementIdentifiers.UserDropDown}
						wide={true}
						choices={Object.fromEntries([
							// Other users
							...alternativeUsers.map(user => [
								user.patient_id.toString(),
								`${user.first_name} ${user.last_name}`
							]),

							// Current user
							[user.user_id.toString(), `${user.first_name} ${user.last_name}`]
						] as [string, string][])}
						initialValue={user.user_id.toString()}
						startIcon={isLoading => (
							<UserGroupIconSolid
								className={`${longerColorTransitionStyles} ${dropDownIconStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>
				</Form>
			</>
		))
	}, [setMenuVisibility, showInputModal, onSwitchUserSubmit, alternativeUsers, user])

	// Hides the menu when the user clicks outside of it
	useEffect(() => {
		const clickEventHandler = (event: MouseEvent): void => {
			if (!isVisible || !setMenuVisibility) return

			// Get the element that was clicked
			const clickedElement = event.target as HTMLElement

			// Don't hide the menu if the user clicked anywhere on this menu
			const accountMenuElement = document.getElementById(HTMLElementIdentifiers.AccountMenu)
			if (accountMenuElement === null || accountMenuElement.contains(clickedElement)) return

			// Don't hide the menu if the user clicked anywhere on the header
			// This is required as without it the menu can never be opened since the open button is within the header!
			const headerElements = document.getElementsByTagName("header")
			if (Array.from(headerElements).some(headerElement => headerElement.contains(clickedElement))) return

			// Hide the menu & remove this event listener
			setMenuVisibility(false)
			window.removeEventListener("click", clickEventHandler)
		}

		// Register the event listener
		window.addEventListener("click", clickEventHandler)

		// Cleanup the event listener
		return (): void => {
			window.removeEventListener("click", clickEventHandler)
		}
	}, [isVisible, setMenuVisibility])

	// Runs after the user is switched...
	useEffect(() => {
		if (switchUserResponse.isUninitialized || switchUserResponse.isFetching || switchUserResponse.isLoading) return

		updateLoading(false)

		if (switchUserResponse.isError) {
			// API error message
			if (isAPIError(switchUserResponse.error)) {
				if (switchUserResponse.error.data.SystemErrorMessage === ErrorMessages.Success.valueOf()) return // API can return success within an error message?!

				console.warn(
					`Failed to switch user! (API said '${switchUserResponse.error.data.SystemErrorMessage?.toString() ?? "Unknown error"}')`
				)
				showWarning(HTMLElementIdentifiers.UserDropDown, "API error! See console for more information.")
				return
			}

			// HTTP error code
			if (isHTTPError(switchUserResponse.error)) {
				console.warn(`Failed to switch user! (HTTP ${switchUserResponse.error.status.toString()})`)
				showWarning(
					HTMLElementIdentifiers.UserDropDown,
					`HTTP ${switchUserResponse.error.status.toString()}! See console for more information.`
				)
				return
			}

			// Fetch aborted (e.g., timeout?)
			if (isStandardError(switchUserResponse.error) && switchUserResponse.error.name === "AbortError") {
				console.warn("Failed to switch user! (Request was aborted)")
				showWarning(HTMLElementIdentifiers.UserDropDown, "The request was aborted! Please try again.")
				return
			}

			// Unknown
			console.error(`Failed to switch user! (${JSON.stringify(switchUserResponse.error)})`)
			showWarning(HTMLElementIdentifiers.UserDropDown, "An unknown error occurred! Please try again later.")
			return
		}

		// Refetch core to update user in global state
		/* eslint-disable promise/prefer-await-to-then */
		console.info("User switched!")
		updateLoading(true)
		refetch()
			.then(result => {
				if (!result.data) {
					console.warn("Cannot update session user without core data!")
					return
				}

				const newUser = tidyUpUser(result.data.patient_details)
				updateSessionUser(newUser)
				console.info("Session user updated!")

				hideModal()

				return result
			})
			.catch((error: unknown) => {
				console.warn(`Failed to refetch core data (${error?.toString() ?? "Unknown error"})`)
				showWarning(HTMLElementIdentifiers.UserDropDown, "Failed to fetch app data! Please try again later.")
			})

			.finally(() => {
				updateLoading(false)
			})
	}, [showWarning, updateLoading, updateSessionUser, hideModal, refetch, switchUserResponse])

	return (
		<div
			id={HTMLElementIdentifiers.AccountMenu}
			{...props}
			className={`${standardTransitionStyles} absolute right-0 z-20 flex flex-col gap-y-3 rounded-b-md bg-primary p-3 ps-4 text-white ${isVisible ? "top-16 opacity-100 shadow-md" : "-top-32 opacity-0"} ${props.className ?? ""}`.trimEnd()}>
			{isSignedIn && (
				<>
					<MenuButton
						label="Messages"
						icon={<ChatBubbleLeftEllipsisIcon width={menuIconSize} height={menuIconSize} />}
						onClick={onMessagesClick}
					/>
					<MenuButton
						label="Settings"
						className="hidden"
						icon={<Cog6ToothIcon width={menuIconSize} height={menuIconSize} />}
						onClick={onSettingsClick}
					/>
					<MenuButton
						label="My Account"
						icon={<UserCircleIcon width={menuIconSize} height={menuIconSize} />}
						onClick={onAccountClick}
					/>
				</>
			)}
			{(isApple() ||
				(globalThis.progressiveWebAppInstallEvent &&
					localStorage.getItem("dismissInstallPrompt") !== "true")) && (
				<MenuButton
					label="Install App"
					icon={<ArrowDownTrayIcon width={menuIconSize} height={menuIconSize} />}
					onClick={onInstallAppClick}
				/>
			)}
			{alternativeUsers.length > 0 && (
				<MenuButton
					label="Switch User"
					icon={<UserGroupIcon width={menuIconSize} height={menuIconSize} />}
					onClick={onSwitchUserClick}
				/>
			)}
			{isSignedIn && (
				<MenuButton
					label="Sign Out"
					icon={<ArrowUpRightIcon width={menuIconSize} height={menuIconSize} />}
					onClick={onSignOutClick}
				/>
			)}
			<MenuButton
				label="About"
				icon={<InformationCircleIcon width={menuIconSize} height={menuIconSize} />}
				onClick={onAboutClick}
			/>
		</div>
	)
}

/**
 * A button within the account menu.
 * @example <MenuButton label="Hello World" icon={<InformationCircleIcon width={20} height={20} />} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const MenuButton = ({
	label,
	icon,
	...props
}: ComponentProps<
	HTMLButtonElement,
	{
		label: string
		icon: JSX.Element
	}
>): JSX.Element => (
	<Button
		{...props}
		label={label}
		className={`gap-x-2 text-white ${props.className ?? ""}`.trimEnd()}
		invisible={true}
		wide={true}
		theme={ButtonThemes.WhiteOnPrimary}
		alignment="right"
		endIcon={icon}
	/>
)

export default AccountMenu
