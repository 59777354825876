import type { ReturnObject } from "ics"
import { createEvent } from "ics"

import type { BookAppointment, BookedAppointment } from "~/api/osteo-physio/types/endpoints/appointments/booked"
import { isBookedAppointment } from "~/api/osteo-physio/types/endpoints/appointments/booked"
import type { AppointmentFilters } from "~/api/osteo-physio/types/endpoints/appointments/filters"
import type { Core } from "~/api/osteo-physio/types/endpoints/data/core"
import type { Appointment } from "~/api/osteo-physio/types/models/appointments/appointment"
import type { Location } from "~/api/osteo-physio/types/models/data/core/location"
import { toGenericOption, type GenericOption } from "~/api/osteo-physio/types/models/data/core/option"
import type { Practitioner } from "~/api/osteo-physio/types/models/data/core/practitioner"
import type { User } from "~/api/osteo-physio/types/models/user/user"
import { toCalendarDateTime, toGoogleCalendarDateTime } from "~/helpers/calendar"
import { isDate } from "~/helpers/date"
import { discardIrrelevantFilterableTypes } from "~/helpers/filterableTypes"
import { toObject } from "~/helpers/object"
import type { Modify } from "~/types/helpers/modify"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const contactEmailAddress = import.meta.env.VITE_CONTACT_EMAIL_ADDRESS
if (!contactEmailAddress) throw new Error("The Osteo & Physio contact email address is missing!")

interface InflateParameters {
	appointment: Appointment | BookedAppointment

	core: Core
	availableFilters?: AppointmentFilters

	chosenTherapyTypeIdentifier?: number
	chosenAppointmentTypeIdentifier?: number
	chosenLocationIdentifier?: number
	chosenPractitionerIdentifier?: number
	chosenGenderIdentifier?: number
	chosenDate?: Date

	skipDayCheck?: boolean
	discardWithoutFilters?: boolean
}

/**
 * An inflated appointment.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export class InflatedAppointment {
	/**
	 * The unique identifier of the appointment.
	 * This is only available for booked appointments!
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly id: number | null = null

	/**
	 * The type of therapy this appointment is for.
	 * This is similar to a category for the type of appointment.
	 * This might not be available in all scenarios!
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly therapy: GenericOption | null = null

	/**
	 * The type of appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly type: GenericOption

	/**
	 * The practitioner's gender.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly gender: GenericOption | null = null

	/**
	 * The location of the appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly location: Location

	/**
	 * The practitioner handling this appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly practitioner: Practitioner

	/**
	 * When this appointment starts.
	 * Only the date component is set for grouped appointments! See the times property for the date's available times.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly startAt: Date

	/**
	 * When this appointment finishes.
	 * Only the date component is set for grouped appointments! See the times property for the date's available times.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly finishAt: Date

	/**
	 * The available times for this appointment.
	 * This is only set for grouped appointments!
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly times: InflatedAppointmentTime[] | null = null

	/**
	 * The cost of the appointment, in Great British Pounds (GBP).
	 * This is expressed as a float, with pounds & pence (e.g., £10.50).
	 * This is null if the appointment is free.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly cost: number | null = null

	/**
	 * Whether the patient would like to be given an earlier appointment if one becomes available.
	 * This is derived from the 'earlier_cancellations_flag' property for booked appointments, or the 'earlier_cancellation_requested' property for future appointments.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly onStandbyForEarlierCancellations: boolean

	/**
	 * Whether the invoice has been issued for this appointment.
	 * This is only available for past appointments!
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly canBeInvoiced: boolean | null = null

	/**
	 * Constructs an inflated appointment.
	 * @returns {InflatedAppointment} A new instance of the inflated appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public constructor({
		id = null,
		therapy = null,
		type,
		gender = null,
		location,
		practitioner,
		startAt,
		finishAt,
		times = null,
		cost = null,
		onStandbyForEarlierCancellations,
		canBeInvoiced = null
	}: {
		id?: number | null
		therapy?: GenericOption | null
		type: GenericOption
		gender?: GenericOption | null
		location: Location
		practitioner: Practitioner
		startAt: Date
		finishAt: Date
		times?: InflatedAppointmentTime[] | null
		cost?: number | null
		onStandbyForEarlierCancellations: boolean
		canBeInvoiced?: boolean | null
	}) {
		this.id = id
		this.therapy = therapy
		this.type = type
		this.gender = gender
		this.location = location
		this.practitioner = practitioner
		this.startAt = startAt
		this.finishAt = finishAt
		this.times = times
		this.cost = cost
		this.onStandbyForEarlierCancellations = onStandbyForEarlierCancellations
		this.canBeInvoiced = canBeInvoiced
	}

	/**
	 * Constructs an easier-to-use appointment.
	 * @param {Appointment | BookedAppointment} appointment The available/booked appointment to inflate.
	 * @param {Core} core The core data.
	 * @param {AppointmentFilters} availableFilters The available filters from fetching this available appointment.
	 * @param {number | undefined} chosenTherapyTypeIdentifier The chosen therapy type identifier.
	 * @param {number | undefined} chosenAppointmentTypeIdentifier The chosen appointment type identifier.
	 * @param {number | undefined} chosenLocationIdentifier The chosen location identifier.
	 * @param {number | undefined} chosenPractitionerIdentifier The chosen practitioner identifier.
	 * @param {number | undefined} chosenGenderIdentifier The chosen gender identifier.
	 * @param {Date | undefined} chosenDate The chosen date.
	 * @param {boolean | undefined} skipDayCheck Whether to skip the day check.
	 * @param {boolean} discardWithoutFilters Whether to discard appointments without filters.
	 * @returns {InflatedAppointment} A new instance of the inflated appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public static inflate = ({
		appointment,

		core,
		availableFilters,

		chosenTherapyTypeIdentifier,
		chosenAppointmentTypeIdentifier,
		chosenLocationIdentifier,
		chosenPractitionerIdentifier,
		chosenGenderIdentifier,
		chosenDate,

		skipDayCheck = false,
		discardWithoutFilters
	}: InflateParameters): InflatedAppointment => {
		// Discard any irrelevant filterable types
		const { therapyTypes, appointmentTypes, genders, locations, practitioners } = discardIrrelevantFilterableTypes(
			core,
			availableFilters,
			discardWithoutFilters
		)

		// Find the data relevant to this appointment
		const therapyType = therapyTypes ? toObject(therapyTypes, id => id === chosenTherapyTypeIdentifier) : undefined // Not on available appointments, but that's okay! We couldn't have possibly got to this stage without a chosen therapy ;)
		const appointmentType = appointmentTypes
			? toObject(appointmentTypes, id => id === (chosenAppointmentTypeIdentifier ?? appointment.type_id))
			: undefined
		const location = locations?.get(chosenLocationIdentifier ?? appointment.location_id)
		const practitioner = practitioners?.get(chosenPractitionerIdentifier ?? appointment.practitioner_id)
		const gender =
			chosenGenderIdentifier !== undefined && genders
				? toObject(genders, id => id === chosenGenderIdentifier) // Not returned on appointments, so we have to base it on the filter that was used! Won't work if 'Any' was selected :(
				: undefined
		if (!appointmentType || !practitioner || !location)
			throw new Error(
				`Skipping inflation of appointment '${appointment.id.toString()}' (${appointment.start_at}) as we're missing data! (${JSON.stringify(appointmentType)}, ${JSON.stringify(practitioner)}, ${JSON.stringify(location)})`
			)

		// Convert ISO 8601 strings to dates
		const startAt = new Date(appointment.start_at)
		const finishAt = new Date(appointment.end_at)
		if (!isDate(startAt) || !isDate(finishAt))
			throw new Error(
				`Skipping inflation of appointment '${appointment.id.toString()}' (${appointment.start_at}) due to invalid dates! (${startAt.toISOString()}, ${finishAt.toISOString()})`
			)

		// Break out date & time components for convenience
		const startDay = startAt.getDate(),
			startMonth = startAt.getMonth(),
			startYear = startAt.getFullYear(),
			startHour = startAt.getHours(),
			startMinute = startAt.getMinutes()
		const finishDay = finishAt.getDate(),
			finishMonth = finishAt.getMonth(),
			finishYear = finishAt.getFullYear(),
			finishHour = finishAt.getHours(),
			finishMinute = finishAt.getMinutes()

		// Give up if the appointment spans across multiple days/months/years
		if (startDay !== finishDay || startMonth !== finishMonth || startYear !== finishYear)
			throw new Error(
				`Skipping appointment '${appointment.id.toString()}' (${appointment.start_at}) as it spans more than a day! (${startAt.toISOString()}, ${finishAt.toISOString()})`
			)

		// Give up if the appointment lasts no length of time
		if (startHour === finishHour && startMinute === finishMinute)
			throw new Error(
				`Skipping inflation of appointment '${appointment.id.toString()}' (${startAt.toISOString()}) as it has no duration! (${startAt.toISOString()}, ${finishAt.toISOString()})`
			)

		// Give up if the appointment isn't for the selected date
		if (
			chosenDate !== undefined &&
			((!skipDayCheck && startDay !== chosenDate.getDate()) ||
				startMonth !== chosenDate.getMonth() ||
				startYear !== chosenDate.getFullYear())
		)
			throw new Error(
				`Skipping inflation of appointment '${appointment.id.toString()}' (${startAt.toISOString()}) as it is not for the selected date! (${chosenDate.toISOString()})`
			)

		// Required properties
		let id = null
		let therapy = therapyType
		let onStandbyForEarlierCancellations = appointment.earlier_cancellations_flag
		let canBeInvoiced = null

		// Only for booked appointments...
		if (isBookedAppointment(appointment)) {
			// Give up if we can't find the relevant therapy type (only for booked appointments!)
			const therapyTypeFromAppointment = therapyTypes
				? toObject(therapyTypes, id => id === appointment.therapy_id)
				: undefined
			if (!therapyTypeFromAppointment)
				throw new Error(
					`Skipping inflation of appointment '${appointment.id.toString()}' (${appointment.start_at}) as we're missing the therapy type! (${JSON.stringify(therapyType)})`
				)

			// Optional properties
			id = appointment.id
			therapy = therapyTypeFromAppointment
			onStandbyForEarlierCancellations =
				appointment.earlier_cancellation_requested ?? onStandbyForEarlierCancellations
			canBeInvoiced = appointment.invoice_issued === true
		}

		const appointmentTypeAsOption = toGenericOption(appointmentType)
		if (!appointmentTypeAsOption) throw new Error("Failed to convert appointment type to option!")

		// Instantiate class
		return new InflatedAppointment({
			id,
			therapy: therapy ? toGenericOption(therapy) : null,
			type: appointmentTypeAsOption,
			gender: gender ? toGenericOption(gender) : null,
			location,
			practitioner,
			startAt,
			finishAt,
			cost: appointment.cost > 0 ? appointment.cost / 100 : null, // Convert pence to pounds (e.g., 1050 = £10.50 -> 10.50 = £10.50),
			onStandbyForEarlierCancellations,
			canBeInvoiced
		})
	}

	/**
	 * Safely inflates an appointment.
	 * @param {Appointment | BookedAppointment} appointment The available/booked appointment to inflate.
	 * @param {Core} core The core data.
	 * @param {AppointmentFilters} availableFilters The available filters from fetching this available appointment.
	 * @param {number | undefined} chosenTherapyTypeIdentifier The chosen therapy type identifier.
	 * @param {number | undefined} chosenAppointmentTypeIdentifier The chosen appointment type identifier.
	 * @param {number | undefined} chosenLocationIdentifier The chosen location identifier.
	 * @param {number | undefined} chosenPractitionerIdentifier The chosen practitioner identifier.
	 * @param {number | undefined} chosenGenderIdentifier The chosen gender identifier.
	 * @param {Date | undefined} chosenDate The chosen date.
	 * @param {boolean} skipDayCheck Whether to skip the day check.
	 * @param {boolean} discardWithoutFilters Whether to discard appointments without filters.
	 * @returns {InflatedAppointment | null} A new instance of the inflated appointment, or null if it failed to inflate.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public static inflateOrNull = ({ ...params }: InflateParameters): InflatedAppointment | null => {
		try {
			return InflatedAppointment.inflate({ ...params })
		} catch (error: unknown) {
			console.warn(`Failed to inflate appointment! (${error?.toString() ?? "Unknown error"})`)
			return null
		}
	}

	/**
	 * Constructs a new appointment from JSON.
	 * This is required as React Router cannot handle classes in navigation state.
	 * @returns {InflatedAppointmentTime} A new instance of the available appointment time.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public static fromJSON = (json: string): InflatedAppointment => {
		const data = JSON.parse(json) as InflatedAppointment | null // Sketchy typing a decoded JSON object as a class!
		if (!data) throw new Error("Invalid JSON for available appointment time!")

		return new InflatedAppointment({
			...data,
			startAt: new Date(data.startAt),
			finishAt: new Date(data.finishAt)
		})
	}

	/**
	 * Groups appointments together by their date.
	 * @param {Appointment} appointment The appointments to group.
	 * @param {Core} core The core data.
	 * @param {Filters} filters The filter used to fetch these appointments.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public static group(appointments: InflatedAppointment[]): InflatedAppointment[] {
		const groupedAppointments = new Map<string, InflatedAppointment>()

		for (const appointment of appointments) {
			const groupedAppointment = groupedAppointments.get(appointment.getUniqueKey())

			// If we've already grouped this appointment, append its times & skip to the next one
			if (groupedAppointment) {
				if (groupedAppointment.times === null)
					throw new Error("No times on appointment that is already grouped?!")

				groupedAppointment.times.push(
					new InflatedAppointmentTime(appointment, appointment.startAt, appointment.finishAt)
				)

				continue
			}

			// Otherwise, group this appointment for the first time
			groupedAppointments.set(appointment.getUniqueKey(), {
				...appointment,

				// Remove the time component from the dates
				startAt: new Date(
					appointment.startAt.getFullYear(),
					appointment.startAt.getMonth(),
					appointment.startAt.getDate(),
					0,
					0,
					0
				),
				finishAt: new Date(
					appointment.finishAt.getFullYear(),
					appointment.finishAt.getMonth(),
					appointment.finishAt.getDate(),
					0,
					0,
					0
				),

				// Add the time component to the times
				times: [new InflatedAppointmentTime(appointment, appointment.startAt, appointment.finishAt)]
			})
		}

		return Array.from(groupedAppointments.values())
	}

	/**
	 * Ensures this instance is valid.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public isValid = (): boolean => {
		/* eslint-disable @typescript-eslint/no-unnecessary-condition */
		if (this.type === null) return false

		if (this.location === null) return false
		if (this.practitioner === null) return false

		if (!isDate(this.startAt)) return false
		if (!isDate(this.finishAt)) return false

		if (this.times !== null && this.times.length === 0) return false

		return true
	}

	/**
	 * Ensures this is an available appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public isAvailable = (): this is Modify<
		InflatedAppointment,
		{
			id: null
			therapy: GenericOption
		}
	> => this.id === null && this.therapy !== null

	/**
	 * Ensures this is a booked appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public isBooked = (): this is Modify<
		InflatedAppointment,
		{
			id: number
			therapy: GenericOption
		}
	> => this.id !== null && this.therapy !== null

	/**
	 * Ensures this is a previously booked appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public isPast = (): this is Modify<
		InflatedAppointment,
		{
			id: number
			therapy: GenericOption

			canBeInvoiced: boolean
		}
	> => this.isBooked() && this.canBeInvoiced !== null && this.finishAt <= new Date()

	/**
	 * Ensures this is an upcoming appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public isFuture = (): this is Modify<
		InflatedAppointment,
		{
			id: number
			therapy: GenericOption
		}
	> => this.isBooked() && this.finishAt >= new Date()

	/**
	 * Ensures this appointment is the result of a grouping.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public isGrouped = (): this is Modify<
		InflatedAppointment,
		{
			times: InflatedAppointmentTime[]
		}
	> => this.times !== null

	/**
	 * Creates a unique key for this appointment.
	 * This can be used by React components when rendering lists of appointments.
	 * This cannot include the time, as it would break grouping!
	 * @returns {string} The unique key for this appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getUniqueKey = (): string =>
		[
			this.id ?? 0,
			this.type.id,
			this.location.id,
			this.practitioner.id,
			this.startAt.getFullYear(),
			this.startAt.getMonth().toString().padStart(2, "0"),
			this.startAt.getDate().toString().padStart(2, "0")
		].join("-")

	/**
	 * Calculates the duration of this appointment.
	 * @returns {number} The duration of this appointment, in minutes.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getDuration = (): number => (this.finishAt.getTime() - this.startAt.getTime()) / 60000

	/**
	 * Gets the human-readable date of when this appointment starts.
	 * This is a shortcut method that uses the toTime() method.
	 * @returns {string} The day, month & year.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getHumanStartDate = (): string => this.toTime().getHumanStartDate()

	/**
	 * Gets the human-readable time of when this appointment starts.
	 * This is a shortcut method that uses the toTime() method.
	 * @returns {string} The start hour & minute, in 24-hour.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getHumanStartTime = (): string => this.toTime().getHumanStartTime()

	/**
	 * Gets the human-readable date of when this appointment finishes.
	 * This is a shortcut method that uses the toTime() method.
	 * @returns {string} The day, month & year.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getHumanFinishDate = (): string => this.toTime().getHumanFinishDate()

	/**
	 * Gets the human-readable time of when this appointment finishes.
	 * This is a shortcut method that uses the toTime() method.
	 * @returns {string} The start hour & minute, in 24-hour.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getHumanFinishTime = (): string => this.toTime().getHumanFinishTime()

	/**
	 * Checks if this appointment is free.
	 * @returns {string} Whether this appointment is free.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public isFree = (): boolean => this.cost === null

	/**
	 * Checks if this appointment has a cost (i.e., is not free).
	 * @returns {string} Whether this appointment has costs.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public hasCost = (): this is {
		cost: number
	} => this.cost !== null && this.cost > 0

	/**
	 * Converts this appointment to its time.
	 * Only applicable for non-grouped appointments!
	 * @returns {InflatedAppointmentTime} The time for this appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public toTime = (): InflatedAppointmentTime => new InflatedAppointmentTime(this, this.startAt, this.finishAt)

	/**
	 * Converts this appointment to a payload that can be consumed by the API.
	 * This is intended for the book appointment & reschedule appointment API routes.
	 * @returns {BadlyTypedBookedAppointment} The payload for this appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public toAPIRequestPayload = (): BookAppointment => ({
		type_id: this.type.id,
		location_id: this.location.id,
		practitioner_id: this.practitioner.id,

		start_at: this.startAt.toISOString(),
		end_at: this.finishAt.toISOString(),

		earlier_cancellations_flag: this.onStandbyForEarlierCancellations
	})

	/**
	 * Converts this appointment to JSON for using as navigation state.
	 * This is required as React Router cannot handle classes in navigation state.
	 * @returns {string} The encoded JSON representing this appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public toJSON = (): string =>
		JSON.stringify({
			id: this.id,
			therapy: this.therapy,
			type: this.type,
			gender: this.gender,
			location: this.location,
			practitioner: this.practitioner,
			startAt: this.startAt.toISOString(),
			finishAt: this.finishAt.toISOString(),
			times: this.times?.map(time => time.toJSON()) ?? null,
			cost: this.cost,
			onStandbyForEarlierCancellations: this.onStandbyForEarlierCancellations,
			canBeInvoiced: this.canBeInvoiced
		})

	/**
	 * Generates a generic ICS calendar event for this appointment.
	 * @param {User} user The user (patient) who booked this appointment.
	 * @returns {ReturnObject} The calendar event for this appointment.
	 * @see https://www.npmjs.com/package/ics#example-usage
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public generateCalendarEvent = (user: User): ReturnObject => {
		const currentDateTime = new Date()

		// TODO: Create a class for the location & add .getLatitude() & .getLongitude() methods
		const [latitude, longitude] = this.location.latlong

		return createEvent({
			title: this.therapy
				? `${this.therapy.name} appointment with ${this.practitioner.name}`
				: `Osteo & Physio appointment with ${this.practitioner.name}`,
			description: `You have an ${this.type.name.toLowerCase()} appointment with ${this.practitioner.name} at ${this.location.name}.`,
			start: toCalendarDateTime(this.startAt),
			end: toCalendarDateTime(this.finishAt),
			created: toCalendarDateTime(currentDateTime),
			lastModified: toCalendarDateTime(currentDateTime),
			location: `${this.location.name}, ${this.location.address}`,
			geo: latitude !== undefined && longitude !== undefined ? { lat: latitude, lon: longitude } : undefined,
			url: "https://osteoandphysio.co.uk",
			status: "CONFIRMED",
			busyStatus: "BUSY",
			organizer: {
				name: "Osteo & Physio",
				email: contactEmailAddress
			},
			attendees: [
				{
					name: this.practitioner.name,
					role: "REQ-PARTICIPANT",
					email: contactEmailAddress
				},
				{
					name: `${user.first_name} ${user.last_name}`,
					email: user.email ?? undefined,
					role: "REQ-PARTICIPANT"
				}
			]
		})
	}

	/**
	 * Generates a URL for adding this appointment to Google Calendar.
	 * @param {User} user The user (patient) who booked this appointment.
	 * @returns {string} The Google Calendar URL for this appointment.
	 * @see https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md
	 * @see https://support.google.com/calendar/thread/how-do-i-generate-add-to-calendar-link-from-our-own-website?hl=en
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public generateGoogleCalendarUrl = (user: User): string => {
		const queryParameters = new URLSearchParams({
			action: "TEMPLATE",
			text: this.therapy
				? `${this.therapy.name} appointment with ${this.practitioner.name}`
				: `Osteo & Physio appointment with ${this.practitioner.name}`,
			details: `You have an ${this.type.name.toLowerCase()} appointment with ${this.practitioner.name} at ${this.location.name}.`,
			dates: `${toGoogleCalendarDateTime(this.startAt)}/${toGoogleCalendarDateTime(this.finishAt)}`,
			ctz: Intl.DateTimeFormat().resolvedOptions().timeZone,
			location: `${this.location.name}, ${this.location.address}`,
			crm: "BUSY",
			trp: "true",
			sprop: "website:https://osteoandphysio.co.uk",
			add: [contactEmailAddress, user.email].filter(email => email !== null).join(",")
		})

		return `https://calendar.google.com/calendar/render?${queryParameters.toString()}`
	}

	/**
	 * Generates a URL for adding this appointment to Microsoft Outlook.
	 * @param {User} user The user (patient) who booked this appointment.
	 * @returns {string} The Microsoft Outlook URL for this appointment.
	 * @see https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/outlook-web.md
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public generateMicrosoftOutlookUrl = (user: User): string =>
		`https://outlook.live.com/calendar/deeplink/compose?${this.generateMicrosoftCalendarQueryParameters(user).toString()}`

	/**
	 * Generates a URL for adding this appointment to Microsoft 365.
	 * @param {User} user The user (patient) who booked this appointment.
	 * @returns {string} The Microsoft 365 URL for this appointment.
	 * @see https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/outlook-web.md
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public generateMicrosoft365Url = (user: User): string =>
		`https://outlook.office.com/calendar/deeplink/compose?${this.generateMicrosoftCalendarQueryParameters(user).toString()}`

	/**
	 * Generates the URL query parameters for Microsoft Outlook or Microsoft 365 calendar URLs.
	 * @param {User} user The user (patient) who booked this appointment.
	 * @returns {string} The query parameters.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public generateMicrosoftCalendarQueryParameters = (user: User): URLSearchParams =>
		new URLSearchParams({
			path: "/calendar/action/compose",
			rru: "addevent",
			subject: this.therapy
				? `${this.therapy.name} appointment with ${this.practitioner.name}`
				: `Osteo & Physio appointment with ${this.practitioner.name}`,
			body: `You have an ${this.type.name.toLowerCase()} appointment with ${this.practitioner.name} at ${this.location.name}.`,
			startdt: this.startAt.toISOString(),
			enddt: this.finishAt.toISOString(),
			allday: "false",
			location: `${this.location.name}, ${this.location.address}`,
			freebusy: "busy",
			to: [contactEmailAddress, user.email].filter(email => email !== null).join(","),
			reqresponse: "false",
			allowfw: "false",
			hideattn: "false"
		})
}

/**
 * An available appointment time.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export class InflatedAppointmentTime {
	/**
	 * The unique key for the parent appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	private readonly appointmentUniqueKey: string

	/**
	 * When this appointment starts.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly startAt: Date

	/**
	 * When this appointment finishes.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public readonly finishAt: Date

	/**
	 * Constructs a new available appointment time.
	 * @param {Date} startAt When this appointment starts.
	 * @param {Date} finishAt When this appointment finishes.
	 * @returns {InflatedAppointmentTime} A new instance of the available appointment time.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public constructor(appointment: InflatedAppointment, startAt: Date, finishAt: Date) {
		this.appointmentUniqueKey = appointment.getUniqueKey()

		// Use the appointment's date, but the time from the parameters
		this.startAt = new Date(
			appointment.startAt.getFullYear(),
			appointment.startAt.getMonth(),
			appointment.startAt.getDate(),
			startAt.getHours(),
			startAt.getMinutes()
		)
		this.finishAt = new Date(
			appointment.finishAt.getFullYear(),
			appointment.finishAt.getMonth(),
			appointment.finishAt.getDate(),
			finishAt.getHours(),
			finishAt.getMinutes()
		)
	}

	/**
	 * Constructs a new available appointment time from JSON.
	 * This is required as React Router cannot handle classes in navigation state.
	 * @returns {InflatedAppointmentTime} A new instance of the available appointment time.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public static fromJSON = (appointment: InflatedAppointment, json: string): InflatedAppointmentTime => {
		const data = JSON.parse(json) as InflatedAppointmentTime | null // Sketchy typing a decoded JSON object as a class!
		if (!data) throw new Error("Invalid JSON for inflated appointment time!")

		return new InflatedAppointmentTime(appointment, new Date(data.startAt), new Date(data.finishAt))
	}

	/**
	 * Creates a unique key for this appointment's time.
	 * This can be used by React components when rendering list of an appointment's times.
	 * @returns {string} The unique key for this appointment.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getUniqueKey = (): string =>
		[this.appointmentUniqueKey, this.getHumanStartTime(), this.getHumanFinishTime()].join("-")

	/**
	 * Gets the human-readable date of when this appointment starts.
	 * @returns {string} The day, month & year.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getHumanStartDate = (): string =>
		this.startAt.toLocaleDateString("en-GB", {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric"
		})

	/**
	 * Gets the human-readable time of when this appointment starts.
	 * @returns {string} The start hour & minute, in 24-hour.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getHumanStartTime = (): string =>
		`${this.startAt.getHours().toString().padStart(2, "0")}:${this.startAt.getMinutes().toString().padStart(2, "0")}`

	/**
	 * Gets the human-readable date of when this appointment finishes.
	 * @returns {string} The day, month & year.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getHumanFinishDate = (): string =>
		this.finishAt.toLocaleDateString("en-GB", {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric"
		})

	/**
	 * Gets the human-readable time of when this appointment finishes.
	 * @returns {string} The start hour & minute, in 24-hour.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getHumanFinishTime = (): string =>
		`${this.finishAt.getHours().toString().padStart(2, "0")}:${this.finishAt.getMinutes().toString().padStart(2, "0")}`

	/**
	 * Calculates the duration of this appointment time.
	 * @returns {number} The duration of this appointment time, in minutes.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public getDuration = (): number => (this.finishAt.getTime() - this.startAt.getTime()) / 60000

	/**
	 * Converts this appointment time to JSON for using as navigation state.
	 * This is required as React Router cannot handle classes in navigation state.
	 * @returns {string} The encoded JSON representing this appointment time.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 2.1.0
	 */
	public toJSON = (): string =>
		JSON.stringify({
			startAt: this.startAt.toISOString(),
			finishAt: this.finishAt.toISOString()
		})
}
