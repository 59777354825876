import type { BookedAppointment } from "~/api/osteo-physio/types/endpoints/appointments/booked"
import { toNumber } from "~/helpers/convert"
import type { Modify } from "~/types/helpers/modify"

/**
 * Tidy up methods should take the exact data returned by the Osteo & Physio API and return a better typed version of it.
 * Property names should be kept identical. No additional properties should be added, and none removed either.
 */

/**
 * Represents a badly typed booked appointment returned by the API.
 * @see https://dev.osteoandphysio.co.uk/appointments/reschedule
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export type BadlyTypedBookedAppointment = Modify<
	BookedAppointment,
	{
		// Why are these numbers typed as strings?!
		therapy_id: string
		earlier_cancellation_requested?: string // Only for future appointments!
	}
>

/**
 * Cleans up the types on a badly typed booked appointment returned by the API.
 * @param {BadlyTypedBookedAppointment} appointment The badly typed booked appointment.
 * @returns {BookedAppointment} The cleaned up booked appointment.
 * @see https://dev.osteoandphysio.co.uk/appointments/reschedule
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export const tidyUpBookedAppointment = (appointment: BadlyTypedBookedAppointment): BookedAppointment =>
	({
		...appointment,
		therapy_id: toNumber(appointment.therapy_id),
		earlier_cancellation_requested:
			appointment.earlier_cancellation_requested !== undefined
				? appointment.earlier_cancellation_requested === "1"
				: undefined
	}) as BookedAppointment
