import type { User } from "~/api/osteo-physio/types/models/user/user"
import { orNull } from "~/helpers/null"
import type { Modify } from "~/types/helpers/modify"

/**
 * Tidy up methods should take the exact data returned by the Osteo & Physio API and return a better typed version of it.
 * Property names should be kept identical. No additional properties should be added, and none removed either.
 */

/**
 * Represents the badly typed user returned by the API.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type BadlyTypedUser = Modify<
	User,
	{
		// API returns this as "" instead of null when unset...?!
		email: string
		home_phone: string
		address_line_1: string
		address_line_2: string
		address_line_3: string
		city: string
		state: string
		post_code: string

		// API returns "1" and "0"..., or sometimes an actual boolean?!
		agree_survey: string | boolean
		agree_reminders: string | boolean
		agree_contact: string | boolean

		// API returns these NUMBERS as strings?!
		association_id: string
		user_id: string
	}
>

/**
 * Cleans up the types on a badly typed user returned by the API.
 * @param {BadlyTypedUser} user The badly typed user.
 * @returns {User} The cleaned up user.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const tidyUpUser = (user: BadlyTypedUser): User => ({
	...user,

	email: orNull(user.email),
	home_phone: orNull(user.home_phone),
	address_line_1: orNull(user.address_line_1),
	address_line_2: orNull(user.address_line_2),
	address_line_3: orNull(user.address_line_3),
	city: orNull(user.city),
	state: orNull(user.state),
	post_code: orNull(user.post_code),

	agree_survey: user.agree_survey === true || user.agree_survey === "1",
	agree_reminders: user.agree_reminders === true || user.agree_reminders === "1",
	agree_contact: user.agree_contact === true || user.agree_contact === "1",

	association_id: parseInt(user.association_id),
	user_id: parseInt(user.user_id)
})
